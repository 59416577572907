<template>
  <div class="avue-top">
    <div class="top-bar__left">
      <div class="avue-breadcrumb" v-if="showCollapse">
        <i
            style="line-height: 50px; font-weight: 10"
            :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
            @click="setCollapse"
        ></i>
      </div>
    </div>
    <h1 class="top-bar__title">
      <div class="top-bar__item top-bar__item--show" v-if="showMenu">
        <top-menu />
      </div>
      <span class="top-bar__item" v-if="showSearch">
        <top-search></top-search>
      </span>
    </h1>
    <div class="top-bar__right">
      <el-tooltip
          v-if="showColor"
          effect="dark"
          :content="$t('top.themeColor')"
          placement="bottom"
      >
        <div class="top-bar__item">
          <top-color />
        </div>
      </el-tooltip>
      <el-tooltip
          v-if="showLock"
          effect="dark"
          :content="$t('top.lockScreen')"
          placement="bottom"
      >
        <div class="top-bar__item">
          <top-lock></top-lock>
        </div>
      </el-tooltip>
      <el-tooltip
          v-if="showTheme"
          effect="dark"
          :content="$t('top.theme')"
          placement="bottom"
      >
        <div class="top-bar__item">
          <top-theme></top-theme>
        </div>
      </el-tooltip>
<!--      <div class="top-bar__item">
        <top-msg></top-msg>
      </div>-->
      <el-tooltip
          v-if="showFullScren"
          effect="dark"
          :content="isFullScren ? $t('top.exitFullscreen') : $t('top.fullscreen')"
          placement="bottom"
      >
        <div class="top-bar__item">
          <i
              :class="isFullScren ? 'icon-tuichuquanping' : 'el-icon-full-screen'"
              @click="handleScreen"
          ></i>
        </div>
      </el-tooltip>
      <el-tooltip
          v-if="this.userInfo.avatar"
          effect="dark"
          :content="$t('top.userAvatar')"
          placement="bottom"
      >
        <div class="top-bar__item" style="margin-right: -5px">
          <img id="thumbnail" class="top-bar__img" :src="userInfo.avatar" />
        </div>
      </el-tooltip>
      <el-dropdown @command="handleCommand">
        <span
            class="el-dropdown-link el-menu-item is-active"
            style="
            background-color: transparent !important;
            padding: 0;
            height: auto;
            line-height: 0;
          "
        >
          <div class="top-bar__item">
            <span>{{ userInfo.username }}</span>
          </div>
          <i
              class="el-icon-arrow-down el-icon--right"
              style="font-size: 15px; margin-left: -15px"
          ></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="/index">{{$t('top.homePage')}}</el-dropdown-item>
          <el-dropdown-item command="/info/index">{{$t('top.userInfo')}}</el-dropdown-item>
<!--          <el-dropdown-item command="">{{$t('top.userPermission')}}</el-dropdown-item>-->
          <el-dropdown-item @click.native="logout" divided
          >{{$t('top.exit')}}
          </el-dropdown-item>
        </el-dropdown-menu>
        <el-dialog
            :title="$t('top.userPermission')"
            v-if="dialogMenuTree"
            :visible.sync="dialogMenuTree"
        >
          <el-tree
              ref="tree"
              :disabled="true"
              default-expand-all
              :data="data"
              show-checkbox
              node-key="menu"
              :props="defaultProps"
          >
          </el-tree>
        </el-dialog>
      </el-dropdown>
<!--      <div class="top-bar__item">
        <top-setting ref="seting"></top-setting>
      </div>-->
      <div class="top-bar__item">
        <top-lang></top-lang>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { fullscreenToggel, listenfullscreen } from "@/util/util";
import topLock from "./top-lock";
import topMenu from "./top-menu";
import topSearch from "./top-search";
import topColor from "./top-color";
import topTheme from "./top-theme";
import topLang from "@/page/index/top/top-lang";
//import topSetting from "./top-setting";
//import topMsg from "./top-msg";

export default {
  components: {
    topLock,
    topMenu,
    topSearch,
    topColor,
    topTheme,
    topLang
    //topSetting,
    //topMsg,
  },
  name: "top",
  data() {
    return {
      treeArr1: [],
      data: [],
      dialogMsgVisible: false,
      dialogMenuTree: false,
      menuTree: [],
      defaultProps: {
        children: "children",
        label: "name",
        value: "menu",
      },
      active: "",
    };
  },
  filters: {},
  mounted() {
    listenfullscreen(this.setScreen);
  },
  computed: {
    ...mapState({
      showDebug: (state) => state.common.showDebug,
      showColor: (state) => state.common.showColor,
      showTheme: (state) => state.common.showTheme,
      showLock: (state) => state.common.showLock,
      showFullScren: (state) => state.common.showFullScren,
      showCollapse: (state) => state.common.showCollapse,
      showSearch: (state) => state.common.showSearch,
      showSelection: (state) => state.common.showSelection,
      showMenu: (state) => state.common.showMenu,
    }),
    ...mapGetters([
      "userInfo",
      "isFullScren",
      "tagWel",
      "tagList",
      "isCollapse",
      "tag",
      /*"logsLen",
      "logsFlag"*/
    ]),
  },
  methods: {
    transferList(data) {
      data.disabled = true;

      for (var i = 0; data.children && i < data.children.length; i++) {
        this.transferList(data.children[i]);
      }
    },
    changeTreeArr(treeArr) {
      for (var i = 0; i < treeArr.length; i++) {
        if (
            treeArr[i].menu &&
            !this.treeArr1.find((value) => value === treeArr[i].menu)
        )
          this.treeArr1.push(treeArr[i].menu);

        for (
            var j = 0;
            treeArr[i].children && j < treeArr[i].children.length;
            j++
        ) {
          if (
              treeArr[i].children[j].menu &&
              !this.treeArr1.find(
                  (value) => value === treeArr[i].children[j].menu
              )
          )
            this.treeArr1.push(treeArr[i].children[j].menu);
        }
      }
    },

    showMenuTree() {
      this.dialogMenuTree = true
    },
    handleScreen() {
      fullscreenToggel()
    },
    setCollapse() {
      this.$store.commit("SET_COLLAPSE");
    },
    setScreen() {
      this.$store.commit("SET_FULLSCREN");
    },
    logout() {
      this.$confirm(this.$t('top.isExit')+","+this.$t('top.isContinue'), this.$t('tip'), {
        confirmButtonText: this.$t('submitText'),
        cancelButtonText: this.$t('cancelText'),
        type: "warning",
      }).then(() => {
        this.$store.dispatch("LogOut").then(() => {
          this.$router.push({ path: "/login" });
        });
      });
    },
    handleCommand(command) {
      if( !command && command !== "" )
        return;

      if ( command !== "" ) {
        let currentPath = this.$router.history.current.path;
        if(command == currentPath){
          return;
        }

        this.$router.push({ path: command });
      } else {
        this.showMenuTree();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
