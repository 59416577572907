import Layout from '@/page/index/'

export default [{
    path: '/',
    component: Layout,
    redirect: '/index',
    children: [{
        path: 'index',
        name: '首页',
        meta: {code: 'home'},
        component: () =>
            import ( /* webpackChunkName: "views" */ '@/page/wel')
    }]
}, {
    path: '/info',
    component: Layout,
    redirect: '/info/index',
    children: [{
        path: 'index',
        name: '个人信息',
        meta: {code: 'userInfo'},
        component: () =>
            import ( /* webpackChunkName: "page" */ '@/views/upms/user/info'),
    }]
}
]
