export default {
    title: 'OTA Upgrade Server',
    tip: 'Tip',
    logoutTip: 'Exit the system, do you want to continue?',
    submitText: 'Confirm',
    cancelText: 'Cancel',
    search: 'Please input search content',
    submit: 'Submit',
    import: 'Import',
    export: 'Export',
    edit: 'Edit',
    updateSuccessMsg: 'Update Success',
    delConfirmMsg: 'Are you sure you want to delete it?',
    warnMsg: 'Warning',
    errorMsg: 'Error',
    delSuccessMsg: 'Delete success',
    successMsg: 'Success',
    addSuccessMsg: 'Added!',
    uploadFailTitle: 'Upload Failed',
    uploadFailMsg: 'File name format error!',
    md5LoadingMsg: 'Calculating MD5 value...',
    login: {
        title: 'Login ',
        username: 'Please input username',
        password: 'Please input a password',
        submit: 'Login',
        downloadLicenseKey: 'download auth key',
        installLicense: 'install license',
        installSuccess: 'Certificate installation successful！',
        installFailed: 'The certificate installation failed！',
        licenseErrorMsg: 'The certificate is invalid, please check whether the server has obtained authorization or reapply for the certificate!'
    },
    top: {
        themeColor: 'Theme Color',
        lockScreen: 'Lock screen',
        theme: 'theme',
        exitFullscreen: 'Exit Full Screen',
        fullscreen: 'Full Screen',
        userAvatar: 'User Avatar',
        homePage: 'Home Page',
        userInfo: 'My information',
        userPermission: 'My Permission',
        exit: 'Exit the system',
        isExit: 'Do you want to exit the system?',
        isContinue: 'Do you want to continue?',
        searchText: 'Please enter search content', //top-lock
        setScreenPassword: 'Set lock screen password',
        screenPassword: 'Lock screen password',
        screenPasswordEmpty: 'Lock screen password cannot be empty',
        enterScreenPassword: 'Please enter the lock screen password', //top-theme
        choiceText: 'Choice',
        themeDefault: 'Theme Default',
        themeStar: 'Theme Star',
        themeBlack: 'Theme Black',
        themeGradual: 'Theme Gradual', //setting
        systemInfo: 'System information',
        setting: 'Setting',
        setMore: '(There are more settings under sliding the mouse)', //tags
        closeOther: 'Close Other',
        closeAll: 'Close All',
        more: 'More',
    },
    route: {
        home: 'Home',
        userInfo: 'User Information',
        root: 'Main Manage',
        system: 'System Manage',
        sysMenu: 'Menu Manage',
        sysUser: 'User Manage',
        sysRole: 'Role Manage',
        sysOrgan: 'Organ Manage',
        sysDict: 'Dict Manage',
        sysLog: 'Logger Manage',
        stb: 'Equipment Manage',
        stbAppVersion: 'Version Manage',
        stbInfo: 'Set-Top Box Manage',
        stbBaseInfo: 'Basic manage',
        iptv: 'IPTV Manage',
        iptvMenu: 'IPTV Menu',
        group: 'Video Group',
        video: 'Video Manage',
        source: 'Source Manage',
        program: 'Program Manage',
        epg: 'EPG Manage',
    },
    curd: {
        selectMsg: "Please select",
        warning: "Cannot be empty",
        createTime: 'Create Time',
        updateTime: 'Update Time',
        importSuccess: 'The Import Success!',
        importFail: 'The import failed!',
        dictDataYES: 'YES',
        dictDataNO: 'NO',
        upms: {
            menu: {
                parentId: 'Parent Menu',
                name: 'Name',
                type: 'Type',
                dicDataMenu: 'Menu',
                dicDataButton: 'Button',
                code: 'Menu Code',
                sort: 'Sort',
                permission: 'Permission Code',
                icon: 'Icon',
                component: 'Menu Path',
                path: 'Menu Code',
                roleId: 'Role',
                addSubMenu: 'Add subordinate menu',
            }, user: {
                username: 'Account',
                password: 'Password',
                newPassword: 'New Password',
                organId: 'Organ',
                roleIds: 'Role',
                lockFlag: 'Lock State',
                email: 'Email',
                phone: 'Phone',
                usernameRequiredMsg: 'Between 3 and 20 characters in length',
                passwordRequiredMsg: 'Between 6 and 20 characters in length',
                phoneRequiredMsg: 'The length is 11 characters',
                accountErrorMsg: 'Account already exists',
                emailErrorMsg: 'Email already exists',
                phoneErrorMsg: 'Phone already exists',
            }, role: {
                roleName: 'Role Name',
                roleCode: 'Role Code',
                roleDesc: 'Role Describe',
                dsType: 'Data permissions',
                dicDataAll: 'All',
                dicDataCustom: 'Custom',
                roleNameSelfOrSubLevel: 'This level and sub-level',
                roleNameSelf: 'This level',
                permission: 'Permission',
                update: 'Update'
            }, organ: {
                parentId: 'Superior Organ',
                name: 'Organ Name',
                type: 'Organ Type',
                code: 'Organ Code',
                sort: 'Sort',
                phone: 'Phone',
                fax: 'Fax',
                address: 'Email',
                comment: 'Remarks',
                errorMsg: 'The organization code already exists',
                email: 'Email'
            }, dict: {
                type: 'Type',
                description: 'Description',
                value: 'Data Value',
                label: 'Label',
                sort: 'Sort',
                keyValList: 'Key value list',
                keyValListType: 'Key value list type:',
                close: 'Close',
            }, sysLog: {
                type: 'Type',
                title: 'Title',
                remoteAddr: 'Remote Address',
                requestUri: 'Request Uri',
                method: 'Method',
                createBy: 'Operator',
                params: 'Request body',
                returnValue: 'Response value',
            }
        },
        stb: {
            base: {
                chipMakers: 'Chip Makers',
                chipModel: 'Chip Model',
                stbModel: 'Stb Model',
                operator: 'Operator',
                stbMemory: 'Stb Memory',
                stbFlash: 'Stb Flash',
                androidVersion: 'Android Version',
            }, info: {
                stbNo: 'Stb Number',
                chipMakers: 'Chip Makers',
                chipModel: 'Chip Model',
                stbModel: 'Stb Model',
                operator: 'Operator',
                stbMemory: 'Stb Memory',
                stbFlash: 'Stb Flash',
                androidVersion: 'Android Version',
                isValid: 'Valid',
                downloadTemplate: 'Download Template',
                template1: 'Template1',
                template2: 'Template2',
            }, sys: {
                stbNo: 'Stb Number',
                chipMakers: 'Chip Makers',
                chipModel: 'Chip Model',
                stbModel: 'Stb Model',
                operator: 'Operator',
                stbMemory: 'Stb Memory',
                stbFlash: 'Stb Flash',
                version: 'Version',
                fileMd5: 'MD5',
                fileSize: 'File Size',
                isForce: 'Force',
                minStbNo: 'Min stb number',
                maxStbNo: 'Max stb number',
                minVersion: 'Min version',
                maxVersion: 'Max version',
                versionContent: 'Update content',
                filePath: 'File Path',
                sftpUpload: 'Upgrade files',
                status: 'Status',
                publishText: 'Publish',
                dicDataNotReleased: 'Unpublished',
                dicDataReleased: 'Published',
                releasedMsg: 'This data has been published',
                releaseSuccess: 'Published',
            }
        },
        iptv: {
            channel: {
                name: 'Channel Name', logo: 'Channel Logo', source: 'Source', logoUpload: 'Upload Logo',
            }, epg: {
                name: 'Name',
                date: 'Play Date',
                startTime: 'Start Time',
                endTime: 'End Time',
                language: 'Language',
                countryName: 'Country',
                rating: 'Rating',
                programInfoId: 'Program',
                description: 'Description',
            }, group: {
                name: 'Name', emmUrl: 'EMM Url', tvMenuId: 'TV Menu', description: 'Description',
            }, menu: {
                name: 'Name', icon: 'Icon', sort: 'Sort', description: 'Description',
            }, program: {
                name: 'Name',
                bgImageUrl: 'Background photo Url',
                cardImageUrl: 'Cover photo url',
                channelInfoId: 'Program Source',
                groupId: 'Group',
                number: 'Program Number',
                isEncrypt: 'Encrypt',
            }, video: {
                title: 'Video Title',
                bgImageUrl: 'Background photo Url',
                cardImageUrl: 'Cover photo url',
                url: 'Play Path',
                isVip: 'Vip',
                groupId: 'GroupId',
                filePath: 'Video path',
                date: 'Year of issuance',
                resolution: 'Resolution',
                size: 'Video size',
                type: 'Video Type',
                mediaType: 'Video Format',
                description: 'Description',
                sftpUpload: 'Video upload',
                uploadTipMsg: 'File name format error！\nThe format is:\'Name [Space] Release Date [Space] Resolution [Space] Other',
            }
        }
    }
}