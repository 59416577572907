// 配置编译环境和线上环境之间的切换
const env = process.env
let iconfontVersion = ['2642665_a1bc5uwpz8g']
//at.alicdn.com/t/c/font_2642665_a1bc5uwpz8g.css
let iconfontUrl = `//at.alicdn.com/t/c/font_$key.css`
if (env.NODE_ENV == 'development') {

} else if (env.NODE_ENV == 'production') {

} else if (env.NODE_ENV == 'test') {

}
export {
  iconfontUrl,
  iconfontVersion,
  env
}
