export default {
    tip: '提示',
    title: 'OTA升级服务器',
    logoutTip: '退出系统, 是否继续?',
    submitText: '确定',
    cancelText: '取消',
    search: '请输入搜索内容',
    submit: '提交',
    import: '导入',
    export: '导出',
    edit: '编辑',
    updateSuccessMsg: '修改成功',
    delConfirmMsg: '是否确认删除?',
    warnMsg: '警告',
    errorMsg: '错误',
    delSuccessMsg: '删除成功',
    successMsg: '成功',
    addSuccessMsg: '添加成功',
    uploadFailTitle: '上传失败',
    uploadFailMsg: '文件名称格式错误!',
    md5LoadingMsg: '正在计算MD5值...',
    login: {
        title: '登录 ',
        username: '请输入账号',
        password: '请输入密码',
        submit: '登录',
        downloadLicenseKey: '下载授权',
        installLicense: '安装证书',
        installSuccess: '证书安装成功！',
        installFailed: '证书安装失败！',
        licenseErrorMsg: ' 证书无效，请核查服务器是否取得授权或重新申请证书！'

    },
    top: {
        themeColor: '主题色',
        lockScreen: '锁屏',
        theme: '特色主题',
        exitFullscreen: '退出全屏',
        fullscreen: '全屏',
        userAvatar: '用户头像',
        homePage: '首页',
        userInfo: '个人信息',
        userPermission: '我的权限',
        exit: '退出系统',
        isExit: '是否退出系统?',
        isContinue: '是否继续?',
        searchText: '请输入搜索内容',
        //top-lock
        setScreenPassword: '设置锁屏密码',
        screenPassword: '锁屏密码',
        screenPasswordEmpty: '锁屏密码不能为空',
        enterScreenPassword: '请输入锁屏密码',
        //top-theme
        choiceText: '选择',
        themeDefault: '默认主题',
        themeStar: '炫彩主题',
        themeBlack: '黑色主题',
        themeGradual: '渐变主题',
        //setting
        systemInfo: '系统信息',
        setting: '设置',
        setMore: '(滑动鼠标下面还有更多设置)',
        //tags
        closeOther: '关闭其他',
        closeAll: '关闭全部',
        more: '更多',
    },
    route: {
        home: '首页',
        userInfo: '个人信息',
        root: '后台管理',
        system: '系统管理',
        sysMenu: '菜单管理',
        sysUser: '用户管理',
        sysRole: '角色管理',
        sysOrgan: '机构管理',
        sysDict: '字典管理',
        sysLog: '日志管理',
        stb: '设备管理',
        stbAppVersion: '版本管理',
        stbInfo: '机顶盒管理',
        stbBaseInfo: '基础信息管理',
        iptv: 'iptv管理',
        iptvMenu: '电视菜单',
        group: '视频分组',
        video: '视频管理',
        source: '节目源管理',
        program: '节目管理',
        epg: 'EPG管理',
    },
    curd: {
        selectMsg: "请选择",
        warning: "不能为空",
        createTime: '创建时间',
        updateTime: '修改时间',
        importSuccess: '导入成功!',
        importFail: '导入失败!',
        dictDataYES: '是',
        dictDataNO: '否',
        upms: {
            menu: {
                parentId: '上级菜单',
                name: '名称',
                type: '类型',
                dicDataMenu: '菜单',
                dicDataButton: '按钮',
                code: '菜单标识',
                sort: '排序',
                permission: '权限标识',
                icon: '图标',
                component: '菜单路径',
                path: '菜单编码',
                roleId: '赋权角色',
                addSubMenu: '添加下级菜单',
            },
            user: {
                username: '账号',
                password: '密码',
                newPassword: '新密码',
                organId: '所属机构',
                roleIds: '角色',
                lockFlag: '允许登录',
                email: '邮箱',
                phone: '手机号',
                usernameRequiredMsg: '长度在 3 到 20 个字符',
                passwordRequiredMsg: '长度在 6 到 20 个字符',
                phoneRequiredMsg: '长度在 11 个字符',
                accountErrorMsg: '账号已经存在',
                emailErrorMsg: '邮箱已经存在',
                phoneErrorMsg: '手机号码已经存在',
            },
            role: {
                roleName: '角色名称',
                roleCode: '角色编码',
                roleDesc: '角色描述',
                dsType: '数据权限',
                dicDataAll: '全部',
                dicDataCustom: '自定义',
                roleNameSelfOrSubLevel: '本级及子级',
                roleNameSelf: '本级',
                permission: '权限',
                update: '更 新'
            },
            organ: {
                parentId: '父类机构',
                name: '机构名称',
                type: '机构类型',
                code: '机构编码',
                sort: '排序',
                phone: '电话',
                fax: '传真',
                address: '邮箱',
                comment: '备注',
                errorMsg: '机构编码已经存在',
                email: '邮箱'
            },
            dict: {
                type: '类型',
                description: '描述',
                value: '数据值',
                label: '标签名',
                sort: '排序',
                keyValList: '键值列表',
                keyValListType: '键值列表，所属类型：',
                close: '关闭',
            },
            sysLog: {
                type: '类型',
                title: '标题',
                remoteAddr: 'IP地址',
                requestUri: '请求路径',
                method: '请求方式',
                createBy: '操作人',
                params: '请求参数',
                returnValue: '返回值',
            }
        },
        stb: {
            base: {
                chipMakers: '芯片厂商',
                chipModel: '芯片型号',
                stbModel: '机顶盒型号',
                operator: '运营商',
                stbMemory: '内存大小',
                stbFlash: 'flash大小',
                androidVersion: '安卓版本号',
            },
            info: {
                stbNo: '机顶盒号',
                chipMakers: '芯片厂商',
                chipModel: '芯片型号',
                stbModel: '机顶盒型号',
                operator: '运营商',
                stbMemory: '内存大小',
                stbFlash: 'flash大小',
                androidVersion: '安卓版本号',
                isValid: '是否生效',
                downloadTemplate: '下载模版',
                template1: '模版1',
                template2: '模版2',
            },
            sys: {
                chipMakers: '芯片厂商',
                chipModel: '芯片型号',
                stbModel: '机顶盒型号',
                operator: '运营商',
                stbMemory: '内存大小',
                stbFlash: 'flash大小',
                version: '版本号',
                fileMd5: 'md5',
                fileSize: '文件大小',
                isForce: '是否强制',
                minStbNo: '最小机顶盒号',
                maxStbNo: '最大机顶盒号',
                minVersion: '最小版本号',
                maxVersion: '最大版本号',
                versionContent: '更新内容',
                filePath: '文件路径',
                sftpUpload: '升级文件',
                status: '状态',
                publishText: '发布',
                dicDataNotReleased: '未发布',
                dicDataReleased: '已发布',
                releasedMsg: '此数据已发布',
                releaseSuccess: '发布成功',
            }
        },
        iptv: {
            channel: {
                name: '频道名称',
                logo: '频道logo',
                source: '频道源地址',
                logoUpload: 'logo上传',
            },
            epg: {
                name: '节目名称',
                date: '播放日期',
                startTime: '开始时间',
                endTime: '结束时间',
                language: '语言',
                countryName: '国家',
                rating: '评级',
                programInfoId: '频道',
                description: '简介',
            },
            group: {
                name: '分组名称',
                emmUrl: 'EMM传输流',
                tvMenuId: '所属菜单',
                description: '简介',
            },
            menu: {
                name: '菜单名称',
                icon: '菜单图标',
                sort: '菜单排序',
                description: '简介',
            },
            program: {
                name: '节目名称',
                bgImageUrl: '背景url',
                cardImageUrl: '封面url',
                channelInfoId: '节目源',
                groupId: '所属分组',
                number: '节目号',
                isEncrypt: '是否加密',
            },
            video: {
                title: '视频标题',
                bgImageUrl: '背景url',
                cardImageUrl: '封面url',
                url: '播放路径',
                isVip: 'vip专享',
                groupId: '所属分组',
                filePath: '视频路径',
                date: '发行年份',
                resolution: '分辨率',
                size: '视频大小',
                type: '视频类型',
                mediaType: '视频格式',
                description: '简介',
                sftpUpload: '视频上传',
                uploadTipMsg: '文件名称格式错误！\n格式为：\'名字[空格]发行日[空格]分辨率[空格]其他',
            }
        },
    }
}