<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  name: 'app',
  data() {
    return {}
  },
  watch: {},
  created() {
  },
  methods: {},
  computed: {}
}
</script>
<style>
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
