import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import VueAxios from 'vue-axios'
import axios from './router/axios'
import i18n from './lang' // Internationalization
import router from './router/router'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css'
import './styles/common.scss'
import '@smallwei/avue/lib/index.css'
import 'babel-polyfill'
import './permission' // 权限
import * as urls from './config/env'
import {iconfontUrl, iconfontVersion} from './config/env'
import animate from 'animate.css'
import Avue from '@smallwei/avue'
import {loadStyle,filterForm} from './util/util'

import * as filters from './filters' // 全局filter
// 注册全局容器
import basicContainer from './components/basic-container/main'
import VideoPlayer from 'vue-video-player'

require('font-awesome/css/font-awesome.css')

Vue.use(VideoPlayer)
require('vue-video-player/src/custom-theme.css')


// 加载相关url地址
Object.keys(urls).forEach(key => {
    Vue.prototype[key] = urls[key]
})
Vue.component('basicContainer', basicContainer)
Vue.use(VueAxios, axios)
Vue.use(ElementUI, {
    i18n: (key, value) => i18n.t(key, value)
})
Vue.use(Avue, {
    i18n: (key, value) => i18n.t(key, value)
})
Vue.use(animate)
//空值过滤器
Vue.prototype.filterForm = filterForm

//加载过滤器
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
})
// 动态加载阿里云字体库
iconfontVersion.forEach(ele => {
    loadStyle(iconfontUrl.replace('$key', ele))
})
window.axios = axios
Vue.config.productionTip = false
Vue.use(ElementUI, {size: 'small'})
Vue.use(Avue)
new Vue({
    store,
    i18n,
    router,
    render: h => h(App),
}).$mount('#app')
