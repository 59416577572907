<template>
  <div class="top-menu">
    <el-menu :default-active="topMenuIndex+''"
             mode="horizontal"
             :active-text-color="theme">
      <template v-for="(item,index) in menu">
        <el-menu-item :index="index+''"
                      @click.native="openMenu(item,index)"
                      :key="index">
          <template slot="title">
            <i :class="item.icon"></i>
            <span>{{$t('route.'+item.code)}}</span>
          </template>
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "top-menu",
  data() {
    return {
    };
  },
  created() {
  },
  computed: {
    ...mapGetters(["tagCurrent", "menu", "theme", "topMenuIndex"])
  },
  methods: {
    openMenu(item,index) {
      this.$store.commit("SET_TOP_MENU_INDEX", index);

      if( item.children != null )
        return;

      this.$router.$avueRouter.group = item.group;
      this.$router.push({
        path: this.$router.$avueRouter.getPath({
          name: item['label'],
          src: item['path']
        }),
        query: item.query
      });
    }
  }
};
</script>
