import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Store from '@/store'
import elementEnLocale from 'element-ui/lib/locale/lang/en'
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'
import enLocale from './en'
import zhLocale from './zh'
import avueZhLocale from '@smallwei/avue/lib/locale/lang/zh'
import avueEnLocale from '@smallwei/avue/lib/locale/lang/en'
Vue.use(VueI18n)
const messages = {
    en: {
        ...enLocale,
        ...elementEnLocale,
        ...avueEnLocale
    },
    zh: {
        ...zhLocale,
        ...elementZhLocale,
        ...avueZhLocale
    },
}
const i18n = new VueI18n({
    silentTranslationWarn: true, // 取消本地化失败时输出的警告
    locale: Store.getters.language,
    messages,
});


export default i18n