/**
 * 全站权限配置
 *
 */
import router from './router/router'
import store from '@/store'
import {validatenull} from '@/util/validate'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
NProgress.configure({showSpinner: false})
const lockPage = store.getters.website.lockPage // 锁屏页
router.beforeEach((to, from, next) => {
    // 缓冲设置
    if (to.meta.keepAlive === true && store.state.tags.tagList.some(ele => {
        return ele && ele.value === to.fullPath
    })) {
        to.meta.$keepAlive = true
    } else {
        NProgress.start()
        if (to.meta.keepAlive === true && validatenull(to.meta.$keepAlive)) {
            to.meta.$keepAlive = true
        } else {
            to.meta.$keepAlive = false
        }
    }
    const meta = to.meta || {}
    if (store.getters.access_token) {
        if (store.getters.isLock && to.path !== lockPage) {
            next({path: lockPage})
        } else if (to.path === '/login') {
            next({path: '/'})
        } else {
            if (!store.getters.userInfo.id) {
                store.dispatch('GetUserInfo').then((res) => {
                    if (res.data.msg === 'ok') {
                        next({...to, replace: true})
                    } else {
                        store.dispatch('FedLogOut').then(() => {
                            next({path: '/login'})
                        })
                    }
                }).catch(() => {
                    store.dispatch('FedLogOut').then(() => {
                        next({path: '/login'})
                    })
                })
            } else {
                const value = to.query.src || to.fullPath
                const label = to.query.name || to.name
                if (meta.isTab !== false && !validatenull(value) && !validatenull(label)) {
                    store.commit('ADD_TAG', {
                        label: label,
                        value: value,
                        params: to.params,
                        query: to.query,
                        meta: meta,
                        group: router.$avueRouter.group || []
                    })
                }
                next()
            }
        }
    } else {
        if (meta.isAuth === false) {
            next()
        } else {
            next('/login')
        }
    }
})

router.afterEach(() => {
    NProgress.done()
    let title = router.$avueRouter.generateTitle(store.getters.tag)
    router.$avueRouter.setTitle(title)
})
