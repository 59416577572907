/**
 * 全站http配置
 *
 * header参数说明
 * serialize是否开启form表单提交
 * isToken是否需要tokenhadoop
 */
import axios from 'axios'
import NProgress from 'nprogress' // progress bar
import store from '@/store'
import router from '@/router/router'

axios.defaults.timeout = 60000
// 返回其他状态码
axios.defaults.validateStatus = function (status) {
    return status >= 200 && status <= 500 // 默认的
}
// 跨域请求，允许保存cookie
axios.defaults.withCredentials = true
axios.interceptors.request.use(config => {
    const url = config.url
    if (url.indexOf("/upload") < 0) {
        NProgress.start() // start progress bar
    }
    const isToken = (config.headers || {}).isToken === false
    let token = store.getters.access_token
    // 如果是需要token的请求，且请求中没有token，且缓存中有token则添加token
    if (token && !isToken) {
        config.headers['token'] = token
    }
    //console.log('【' + url + '】%c请求参数', 'color: green;');
    //console.log(config);
    return config
}, error => {
    //console.log(error)
    return Promise.reject(error)
})
axios.interceptors.response.use(res => {
    NProgress.done()
    const status = Number(res.status) || 200
    if (status === 401) {
        store.dispatch('FedLogOut').then(() => {
            router.push({path: '/'})
        })
        return
    }
    if (status !== 200 || res.data.code !== 200) {
        return Promise.reject(new Error(res.data.msg))
    }
    //console.log('【' + res.config.url + '】%c返回结果', 'color: green;');
    //console.log(res);
    return res
}, error => {
    //console.log(error)
    NProgress.done()
    return Promise.reject(new Error(error))
})
export default axios
