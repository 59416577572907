<template>
  <div class="avue-contail"
       :class="{'avue--collapse':isCollapse}">
    <div class="avue-header">
      <!-- 顶部导航栏 -->
      <top/>
    </div>

    <div class="avue-layout">
      <div class="avue-left">
        <!-- 左侧导航栏 -->
        <sidebar/>
      </div>
      <div class="avue-main">
        <!-- 顶部标签卡 -->
        <tags />
        <!-- 主体视图层 -->
        <el-scrollbar style="height:100%">
          <keep-alive>
            <router-view class="avue-view"
                         v-if="$route.meta.$keepAlive"/>
          </keep-alive>
          <router-view class="avue-view"
                       v-if="!$route.meta.$keepAlive"/>
        </el-scrollbar>

      </div>
    </div>
    <div class="avue-shade"
         @click="showCollapse"></div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Sidebar from "@/page/index/sidebar";
import {validatenull} from '@/util/validate';
import {getStore} from '@/util/store.js';
import Top from "@/page/index/top";
import Tags from "@/page/index/tags";

export default {
  name: "index",
  components: {Tags, Top, Sidebar},
  data() {
    return {
      //刷新token锁
      refreshLock: false,
      //刷新token的时间
      refreshTime: '',
      // 计时器
      timer: '',
    }
  },
  created() {
    //实时检测刷新token
    this.refreshToken()
  },
  destroyed() {
    clearInterval(this.refreshTime)
    clearInterval(this.timer)
    this.disconnect()
  },
  mounted() {
    this.init()
  },
  computed: mapGetters(['userInfo', 'isLock', 'isCollapse', 'website', 'expires_in']),
  props: [],
  methods: {
    showCollapse() {
      this.$store.commit("SET_COLLAPSE")
    },
    // 屏幕检测
    init() {
     /* this.$store.commit('SET_SCREEN', admin.getScreen())
      window.onresize = () => {
        setTimeout(() => {
          this.$store.commit('SET_SCREEN', admin.getScreen())
        }, 0);
      }*/
    },
    // 实时检测刷新token
    refreshToken() {
      this.refreshTime = setInterval(() => {
        const token = getStore({
          name: 'access_token',
          debug: true,
        });

        if (validatenull(token)) {
          return;
        }

        if (this.expires_in <= 1000 && !this.refreshLock) {
          this.refreshLock = true
          this.$store
              .dispatch('RefreshToken')
              .catch(() => {
                clearInterval(this.refreshTime)
              });
          this.refreshLock = false
        }
        this.$store.commit("SET_EXPIRES_IN", this.expires_in - 10);
      }, 10000);
    },
    disconnect() {
      /*if (this.stompClient != null) {
        this.stompClient.disconnect();
        console.log("Disconnected");
      }*/
    }
  }
}
</script>

<style scoped>

</style>